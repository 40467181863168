<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="addForm" ref="form" :rules="couponRules" :model="formData" label-width="120px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formData.title" size="small"></el-input>
      </el-form-item>
      <el-form-item label="是否显示">
        <el-switch v-model="formData.is_publish" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
      </el-form-item>

      <el-form-item label="发布时间" prop="publish_time">
        <el-date-picker
          v-model="formData.publish_time"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="case_intro" label="案件描述">
        <!-- <Editor ref="editor" :showBtn="true" :content.sync="formData.case_intro"></Editor> -->
        <el-input style="width: 400px" rows="5" type="textarea" v-model="formData.case_intro" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item prop="content" label="案件解释">
        <Editor ref="editor" :showBtn="true" :content.sync="formData.content"></Editor>
      </el-form-item>
      <el-form-item label="视频">
        <UploadVideo :videoUrl.sync="formData.video_url"></UploadVideo>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { detailAPI, editAPI, createAPI } from './api'
import Editor from '@/components/editor/index.vue'
import UploadVideo from '@/components/uploader/uploader-video.vue'
export default {
  name: 'addOrEdit',
  components: {
    Editor,
    UploadVideo
  },

  data() {
    return {
      rules: [],
      formData: {
        title: '',
        content: '',
        case_intro: '',
        publish_time: '',
        is_publish: 1,
        video_url: ''
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      dialogVisible: false,
      currentPage: 1,
      page_size: 5,
      total: 0,
      list: [{ id: 111 }, { id: 222 }],
      searchData: {},
      couponRules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        publish_time: [{ required: true, message: '发布不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '公告内容不能为空', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            createAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await detailAPI(this.$route.params.id)
    },
    selectGoods() {
      this.dialogVisible = true
    },
    handleSelectionChange(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .addForm {
    .el-form-item {
      .el-input {
        width: 400px;
      }

      .tips {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        line-height: 30px;
      }

      .el-range-separator {
        padding: 0;
      }
    }
  }

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 10px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
}
</style>
